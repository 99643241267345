import { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Breadcrumbs,
    Link,
    Button,
    FormControl,
    InputAdornment,
    TextField,
    MenuItem,
    Select,
    Tooltip,
    IconButton,
    SelectChangeEvent,
} from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useShoppingCart } from '../../../../../context/ShoppingCartContext';
import { useAuth } from '../../../../../context/AuthContext';
import { useQuery } from 'react-query';
import { apiGetAllBrands } from '../../../../../api/apiBrands';
import { apiGetStockRollData } from '../../../../../api/apiProducts';
import StockRollModal from '../../../modal/StockRollModal';

interface ImageMatch {
    image_data?: string;
    file_name?: string;
    brand_name?: string;
}

const ProductSection = ({
                            productData,
                            currentIndex,
                            setCurrentIndex,
                            itemGroup,
                            application,
                            collection,
                            design,
                            currentSelectedItem,
                            setCurrentSelectedItem,
                            setSelectedImagePreviewIndex,
                            selectedImagePreviewIndex,
                            setWidth,
                            setLengthCM,
                            setDiameter,
                            diameter,
                            width,
                            lengthCM,
                            activeImageStep, // <--- now received from parent
                            setActiveImageStep, // <--- added prop
                            productListData,
                        }: any) => {
    const base = window.location.origin;
    const [rugSizes, setRugSizes]: any = useState([]);
    const [selectedSize, setSelectedSize] = useState('All Sizes');
    const [isFromDropdown, setIsFromDropdown] = useState(false);
    const { isLogin } = useAuth();
    const { addToCart, setStockLink, discountAmount } = useShoppingCart();
    const [more, setMore] = useState(window.innerWidth > 600 ? 6 : 3);
    const [imageMatch, setImageMatch] = useState<ImageMatch>({});

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const handleOpenModal = () => {
        setModalIsOpen(true);
    };

    const handleCloseModal = () => {
        setModalIsOpen(false);
    };

    const {
        isSuccess,
        isError,
        isLoading,
        data: stockRollData,
        refetch,
    } = useQuery<any, Error>(
        [`stockroll-${currentSelectedItem?.sku}`],
        () => apiGetStockRollData(currentSelectedItem?.sku),
        { staleTime: 600000 }
    );

    const productDataItems = productData?.slice(0, -1) || [];

    const showMore = () => {
        setMore(productDataItems?.length);
    };

    const showLess = () => {
        setMore(window.innerWidth > 600 ? 6 : 3);
    };

    const [categoryQuantity, setCategoryQuantity] = useState<number | ''>(1);

    const calculateTradePrice = () => {
        let price = discountAmount?.discount_total;
        return price;
    };

    const [showFullDescription, setShowFullDescription] = useState(false);
    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };

    // Modify the useEffect that updates currentSelectedItem
    useEffect(() => {
        if (!isFromDropdown) {  // Only run if not triggered by dropdown
            let tradePrice = calculateTradePrice();
            setCurrentSelectedItem({
                image: productDataItems[currentIndex]?.Product?.primaryImageUrl,
                name: productDataItems[currentIndex]?.Product?.colour,
                quantity: categoryQuantity,
                price: tradePrice,
                stock: productDataItems[currentIndex]?.StockMaster?.on_hand,
                sku: productDataItems[currentIndex]?.Product?.sku,
                stock_link: productDataItems[currentIndex]?.StockMaster?.stock_link,
                item_group_code: productDataItems[currentIndex]?.StockMaster?.item_group,
                description: productDataItems[currentIndex]?.StockMaster?.description_1,
                group: productDataItems[currentIndex]?.StockMaster?.group_desc,
                id: productDataItems[currentIndex]?.Product?.id,
                discount_type: discountAmount?.discount_type,
                availableColourImages:
                    productDataItems[currentIndex]?.Product?.additionalImages === null
                        ? [{ image_data: productDataItems[currentIndex]?.Product?.primaryImageUrl }]
                        : productDataItems[currentIndex]?.Product?.additionalImages,
            });
        }
    }, [
        currentIndex,
        productDataItems,
        setCurrentSelectedItem,
        calculateTradePrice,
        categoryQuantity,
        discountAmount?.discount_type,
        discountAmount?.discount_total,
        isFromDropdown
    ]);

    useEffect(() => {
        const updatedStockLink = productDataItems[currentIndex]?.StockMaster?.stock_link;
        if (isLogin && updatedStockLink) {
            setStockLink(updatedStockLink);
        }
    }, [currentIndex, productDataItems, setStockLink, isLogin]);

    useQuery(
        ['brands', productDataItems[currentIndex]?.StockMaster?.brand],
        async () => {
            const response = await apiGetAllBrands();
            if (response) {
                const foundImageMatch = response.find(
                    (element: any) =>
                        element.brand_name === productDataItems[currentIndex]?.StockMaster?.brand
                );
                setImageMatch(foundImageMatch);
            }
            return response;
        },
        {
            initialData: [],
        }
    );

    const timeStamp = Number(
        productDataItems[currentIndex]?.StockMaster?.po_delivery_date
    );
    const date = new Date(timeStamp);
    const isValidDate = timeStamp && date.getTime() !== 0;

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    // Extract dimensions from productData to populate the dropdown
    useEffect(() => {
        const findDimensions = () => {
            if (productData && productData.length > 0) {
                const lastItem = productData[productData.length - 1];
                const rugDiameters = lastItem.required_full_key_info.diameter
                    .filter((elem: any) => !/^0*\.?0+\s*(?:cm)?$/i.test(elem))
                    .map((elem: any) => ({ dimensions: elem }));
                const rugHeights = lastItem.required_full_key_info.length_cm.map(
                    (elem: any) => ({ height: elem })
                );
                const updatedRugSizes = lastItem.required_full_key_info.width
                    .map((elem: any, index: number) => ({
                        dimensions: `${elem}x${rugHeights[index]?.height}`,
                    }))
                    .filter(
                        (elem: any) =>
                            !/^(0*\.?0+\s*(?:cm)?\s*[xX]\s*0*\.?0+\s*(?:cm)?|0*\.?0+\s*(?:cm)?)$/i.test(
                                elem.dimensions
                            )
                    );

                setRugSizes([...updatedRugSizes, ...rugDiameters]);
            }
        };

        findDimensions();
    }, [productData]);

    useEffect(() => {
        if (productData && productData.length > 0 && !isFromDropdown) {  // Only run if not from dropdown
            const lastItem = productData[productData.length - 1];

            if (diameter) {
                const matchingSize = lastItem.required_full_key_info.diameter.find(
                    (d: string) => d.trim() === diameter.trim()
                );
                if (matchingSize) {
                    setSelectedSize(matchingSize.trim());
                }
            } else if (width && lengthCM) {
                const matchingSizeIndex = lastItem.required_full_key_info.width.findIndex(
                    (w: string, index: number) =>
                        w.trim() === width.trim() &&
                        lastItem.required_full_key_info.length_cm[index].trim() ===
                        lengthCM.trim()
                );
                if (matchingSizeIndex !== -1) {
                    setSelectedSize(`${width.trimStart()}x${lengthCM.trimStart()}`);
                }
            }
        }
        setIsFromDropdown(false);  // Reset the flag
    }, [productData, diameter, width, lengthCM, isFromDropdown]);



    const handleSizeChange = (event: SelectChangeEvent<string>) => {
        event.preventDefault();
        event.stopPropagation();

        if (productData && productData.length > 0) {
            const selectedSizeValue = event.target.value;

            if (selectedSizeValue === 'All Sizes') {
                setIsFromDropdown(true);
                setSelectedSize(selectedSizeValue);
                Promise.resolve().then(() => {
                    setWidth('');
                    setLengthCM('');
                    setDiameter('');
                    setCurrentIndex(0);
                    setSelectedImagePreviewIndex(0);
                    setActiveImageStep(0);
                });
                return;
            }

            const dimensions = selectedSizeValue.split('x').map(s => s.trim());

            // Get current color
            const currentColor = productDataItems[currentIndex]?.StockMaster?.colour;

            // Find matching indices with the same color
            const matchingIndices = productDataItems.reduce((acc: number[], item: any, index: number) => {
                const itemColor = item.StockMaster?.colour;

                // Only consider items with the same color
                if (itemColor === currentColor) {
                    if (dimensions.length === 2) {
                        const [selectedWidth, selectedLength] = dimensions;
                        if (item.StockMaster.width.trim() === selectedWidth &&
                            item.StockMaster.length_cm.trim() === selectedLength) {
                            acc.push(index);
                        }
                    } else if (item.StockMaster.diameter.trim() === dimensions[0]) {
                        acc.push(index);
                    }
                }
                return acc;
            }, []);

            if (matchingIndices.length > 0) {
                const selectedIndex = matchingIndices[0];
                setIsFromDropdown(true);
                setSelectedSize(selectedSizeValue);

                Promise.resolve().then(() => {
                    if (dimensions.length === 2) {
                        const [selectedWidth, selectedLength] = dimensions;
                        setWidth(selectedWidth);
                        setLengthCM(selectedLength);
                        setDiameter('');
                    } else {
                        setDiameter(dimensions[0]);
                        setWidth('');
                        setLengthCM('');
                    }

                    setCurrentIndex(selectedIndex);
                    setSelectedImagePreviewIndex(selectedIndex);
                    setActiveImageStep(0);
                });
            }
        }
    };

// Modify the handleThumbnailClick function:

    const handleThumbnailClick = (index: number, event: React.MouseEvent<HTMLDivElement>) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        const item = productDataItems[index];
        if (!item) return;

        setIsFromDropdown(false);

        // Batch state updates
        Promise.resolve().then(() => {
            const width = item.StockMaster.width.trim();
            const length = item.StockMaster.length_cm.trim();
            const diameter = item.StockMaster.diameter.trim();

            setActiveImageStep(0);
            setSelectedImagePreviewIndex(index);
            setCurrentIndex(index);

            if (width !== '0cm' && length !== '0cm') {
                setWidth(width);
                setLengthCM(length);
                setDiameter('');
                setSelectedSize(`${width}x${length}`);
            } else if (diameter !== '0cm') {
                setDiameter(diameter);
                setWidth('');
                setLengthCM('');
                setSelectedSize(diameter);
            }
        });
    };


    useEffect(() => {
        return () => {
            setIsFromDropdown(false);
        };
    }, []);

    // Determine the currently displayed image based on activeImageStep
    const displayedImages = [
        productDataItems[currentIndex]?.Product?.primaryImageUrl,
        ...(productDataItems[currentIndex]?.Product?.additionalImages ?? []).map(
            (img: any) => img.image_data
        ),
    ];
    const displayedImage =
        displayedImages[activeImageStep] ||
        productDataItems[currentIndex]?.Product?.primaryImageUrl;

    // Helper function to get size based on currentIndex
    const getSizeForIndex = (index: number): string => {
        const item = productDataItems[index];
        if (item.required_full_key_info.diameter && item.required_full_key_info.diameter.length > 0) {
            // Assuming the first diameter is the relevant one
            return item.required_full_key_info.diameter[0].trim();
        } else if (
            item.required_full_key_info.width &&
            item.required_full_key_info.length_cm &&
            item.required_full_key_info.width.length > 0 &&
            item.required_full_key_info.length_cm.length > 0
        ) {
            // Assuming the first width and length_cm are relevant
            return `${item.required_full_key_info.width[0].trim()}x${item.required_full_key_info.length_cm[0].trim()}`;
        }
        return 'All Sizes';
    };

    return (
        <Box
            sx={{
                flex: 1,
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                pr: { xs: '0', lg: '50px' },
                overflow: 'hidden',
            }}
        >
            <Box
                sx={{
                    padding: {
                        xs: '0 0',
                        lg: '0 2rem',
                    },
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {/* Breadcrumbs */}
                <Breadcrumbs
                    sx={{
                        display: {
                            xs: 'none',
                            sm: 'block',
                        },
                    }}
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    <Link underline="hover" color="inherit" href={`${base}/item_group/${itemGroup}`}>
                        {productDataItems[0]?.StockMaster?.group_desc || 'Group'}
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        href={`${base}/item_group/${itemGroup}/application/${encodeURIComponent(
                            application
                        )}`}
                    >
                        {application || 'Application'}
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        href={`${base}/item_group/${itemGroup}/application/${encodeURIComponent(
                            application
                        )}/collection/${encodeURIComponent(collection)}`}
                    >
                        {collection || 'Collection'}
                    </Link>
                    <Typography color="text.primary">{design || 'Design'}</Typography>
                </Breadcrumbs>

                {/* Generic Details */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: 'auto',
                    }}
                >
                    <Box>
                        <Typography
                            mt={1}
                            variant="h5"
                            fontSize={'40px'}
                            fontWeight={'500'}
                            lineHeight={'40px'}
                        >
                            {productDataItems[0]?.StockMaster?.design || 'Design Name'}
                        </Typography>
                        <Typography fontWeight="600" lineHeight={'24px'} fontSize={'16px'}>
                            {productDataItems[currentIndex]?.StockMaster?.colour || 'Colour'}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'end',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            src={imageMatch?.image_data || '/default-brand.png'}
                            alt={imageMatch?.brand_name || 'Brand'}
                            style={{
                                objectFit: 'contain',
                                width: '30%',
                            }}
                        />
                    </Box>
                </Box>

                {/* Product Description */}
                {productDataItems[currentIndex]?.Product?.long_description.length === '' ? (
                    '0'
                ) : (
                    <Typography
                        mt={2}
                        fontSize={'16px'}
                        fontWeight={'400'}
                        lineHeight={'25px'}
                    >
                        {productDataItems[currentIndex]?.Product?.long_description.length > 500
                            ? showFullDescription
                                ? productDataItems[currentIndex]?.Product?.long_description
                                : productDataItems[currentIndex]?.Product?.long_description.substring(0, 500) + '...'
                            : productDataItems[currentIndex]?.Product?.long_description}
                        {productDataItems[currentIndex]?.Product?.long_description.length > 500 && (
                            <Button
                                size="small"
                                onClick={toggleDescription}
                                sx={{
                                    textTransform: 'none',
                                    cursor: 'pointer',
                                    p: '0',
                                    height: '0',
                                    minWidth: '0',
                                    mt: 2,
                                }}
                            >
                                {showFullDescription ? 'Less' : 'More'}
                            </Button>
                        )}
                    </Typography>
                )}

                {/* Available Sizes Dropdown */}
                {(itemGroup === '004' || itemGroup === '000') && (
                    <Box
                        sx={{
                            mt: '10px',
                            width: { xs: '100%', lg: '50%' },
                        }}
                    >
                        <Box
                            sx={{
                                mt: 2,
                                width: '100%',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    color: '#344054',
                                }}
                            >
                                Available Sizes:
                            </Typography>

                            <FormControl fullWidth>
                                <Select
                                    labelId="size-select-label"
                                    id="size-select"
                                    size="small"
                                    inputProps={{
                                        sx: {
                                            p: '10px 14px',
                                        },
                                    }}
                                    onChange={handleSizeChange}
                                    value={selectedSize}
                                    onClick={(e) => e.stopPropagation()}
                                    onMouseDown={(e) => e.stopPropagation()}
                                >
                                    <MenuItem value="All Sizes">All Sizes</MenuItem>
                                    {rugSizes.map((size: any, index: any) => (
                                        <MenuItem
                                            key={index}
                                            value={size.dimensions.trim()}
                                        >
                                            {size.dimensions.includes('x')
                                                ? `${size.dimensions
                                                    .split('x')[0]
                                                    .trim()} W x ${size.dimensions
                                                    .split('x')[1]
                                                    .trim()} L`
                                                : `${size.dimensions} - Diameter`}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                )}

                {/* Quantity Selector */}
                <Box
                    sx={{
                        mt: '10px',
                        width: '100%',
                    }}
                >
                    <FormControl
                        sx={{
                            width: { xs: '100%', lg: '20%' },
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontWeight: '600',
                                color: '#344054',
                            }}
                        >
                            Quantity
                        </Typography>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            type="number"
                            value={categoryQuantity}
                            size="small"
                            inputProps={{
                                sx: {
                                    p: '10px 14px',
                                },
                            }}
                            onChange={(event) => {
                                const value = event.target.value;
                                if (
                                    productDataItems[currentIndex]?.StockMaster?.group_desc ===
                                    'Fabric'
                                ) {
                                    const regex = /^\d*\.?\d{0,1}$/;
                                    if (value === '' || regex.test(value)) {
                                        setCategoryQuantity(value === '' ? '' : Number(value));
                                    }
                                } else {
                                    const regex = /^\d*$/;
                                    if (value === '' || regex.test(value)) {
                                        setCategoryQuantity(value === '' ? '' : Number(value));
                                    }
                                }
                            }}
                            InputProps={{
                                sx: {
                                    width: '100%',
                                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                        '-webkit-appearance': 'none',
                                        margin: 0,
                                    },
                                    '& input[type="number"]': {
                                        '-moz-appearance': 'textfield',
                                    },
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Typography
                                            sx={{
                                                fontWeight: '400',
                                                color: '#000',
                                                fontSize: '14px',
                                            }}
                                        >
                                            {productDataItems[currentIndex]?.StockMaster?.group_desc === 'Fabric'
                                                ? 'm'
                                                : productDataItems[currentIndex]?.StockMaster?.group_desc === 'Wallpaper'
                                                    ? 'Roll(s)'
                                                    : 'unit'}
                                        </Typography>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>
                </Box>

                {/* Pricing and Stock Information */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', lg: 'row' },
                        justifyContent: 'space-between',
                        gap: { xs: '2px', lg: '8px' },
                    }}
                >
                    {/* Pricing Section */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            backgroundColor: '#F7F7F7',
                            width: '100%',
                            p: '12px 16px',
                            mt: '1rem',
                        }}
                    >
                        {productDataItems[currentIndex]?.StockMaster?.group_desc === 'Fabric' ? (
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    alignItems: 'start',
                                }}
                            >
                                {/* Cut Price */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: '100%',
                                    }}
                                >
                                    <Typography
                                        fontSize={'11px'}
                                        textTransform={'uppercase'}
                                        fontWeight={'700'}
                                    >
                                        Cut Price (ex VAT)
                                    </Typography>
                                    <Typography fontWeight="700" fontSize={'12px'}>
                                        R
                                        {(
                                            productDataItems[currentIndex]?.StockMaster?.cut_price_ex_vat *
                                            (categoryQuantity ? categoryQuantity : 1)
                                        ).toFixed(2)}
                                    </Typography>
                                </Box>

                                {/* Retail Price */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'flex-end',
                                        width: '100%',
                                        marginTop: '4px',
                                    }}
                                >
                                    <Typography
                                        fontSize={'11px'}
                                        textTransform={'uppercase'}
                                        fontWeight={'700'}
                                    >
                                        Recommended Retail Price (incl VAT)
                                    </Typography>
                                    <Typography fontWeight="700" fontSize={'12px'}>
                                        R
                                        {(
                                            productDataItems[currentIndex]?.StockMaster?.price_retail_inc_vat *
                                            (categoryQuantity ? categoryQuantity : 1)
                                        ).toFixed(2)}
                                    </Typography>
                                </Box>
                            </Box>
                        ) : productDataItems[currentIndex]?.StockMaster?.group_desc === 'Wallpaper' ? (
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    alignItems: 'start',
                                }}
                            >
                                {/* Cut Price */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'flex-end',
                                        width: '100%',
                                    }}
                                >
                                    <Typography
                                        fontSize={'10px'}
                                        textTransform={'uppercase'}
                                        fontWeight={'700'}
                                    >
                                        Cut Price (ex VAT)
                                    </Typography>
                                    <Typography fontWeight="700" fontSize={'12px'}>
                                        R
                                        {(
                                            productDataItems[currentIndex]?.StockMaster?.cut_price_ex_vat *
                                            (categoryQuantity ? categoryQuantity : 1)
                                        ).toFixed(2)}
                                    </Typography>
                                </Box>

                                {/* Retail Price */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'flex-end',
                                        width: '100%',
                                        marginTop: '4px',
                                    }}
                                >
                                    <Typography
                                        fontSize={'10px'}
                                        textTransform={'uppercase'}
                                        fontWeight={'700'}
                                    >
                                        Recommended Retail Price (incl VAT)
                                    </Typography>
                                    <Typography fontWeight="700" fontSize={'12px'}>
                                        R
                                        {(
                                            productDataItems[currentIndex]?.StockMaster?.price_retail_inc_vat *
                                            (categoryQuantity ? categoryQuantity : 1)
                                        ).toFixed(2)}
                                    </Typography>
                                </Box>
                            </Box>
                        ) : (
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    alignItems: 'start',
                                }}
                            >
                                {/* Unit Price */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: '100%',
                                    }}
                                >
                                    <Typography
                                        fontSize={'11px'}
                                        textTransform={'uppercase'}
                                        fontWeight={'700'}
                                    >
                                        Unit Price (ex VAT)
                                    </Typography>
                                    <Typography fontWeight="700" fontSize={'12px'}>
                                        R
                                        {(
                                            productDataItems[currentIndex]?.StockMaster?.cut_price_ex_vat *
                                            (categoryQuantity ? Number(categoryQuantity) : 1)
                                        ).toFixed(2)}
                                    </Typography>
                                </Box>
                                {/* Retail Price */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'flex-end',
                                        width: '100%',
                                        marginTop: '4px',
                                    }}
                                >
                                    <Typography
                                        fontSize={'11px'}
                                        textTransform={'uppercase'}
                                        fontWeight={'700'}
                                    >
                                        Retail Price (incl VAT)
                                    </Typography>
                                    <Typography fontWeight="700" fontSize={'12px'}>
                                        R
                                        {(
                                            productDataItems[currentIndex]?.StockMaster?.price_retail_inc_vat *
                                            (categoryQuantity ? Number(categoryQuantity) : 1)
                                        ).toFixed(2)}
                                    </Typography>
                                </Box>
                            </Box>
                        )}
                    </Box>

                    {/* Stock Information */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'start',
                            backgroundColor: '#F7F7F7',
                            width: '100%',
                            p: '12px 16px',
                            mt: '1rem',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                            }}
                        >
                            {productDataItems[currentIndex]?.StockMaster?.group_desc === 'Fabric' ? (
                                <Typography
                                    fontSize={'11px'}
                                    textTransform={'uppercase'}
                                    fontWeight={'700'}
                                >
                                    Meters on Hand
                                </Typography>
                            ) : productDataItems[currentIndex]?.StockMaster?.group_desc === 'Wallpaper' ? (
                                <Typography
                                    fontSize={'11px'}
                                    textTransform={'uppercase'}
                                    fontWeight={'700'}
                                >
                                    Rolls on Hand
                                </Typography>
                            ) : (
                                <Typography
                                    fontSize={'11px'}
                                    textTransform={'uppercase'}
                                    fontWeight={'700'}
                                >
                                    Units on Hand
                                </Typography>
                            )}
                            {itemGroup === '005' && (
                                <Button
                                    disableElevation
                                    variant="text"
                                    sx={{
                                        background: '#F2F4F7',
                                        color: '#000',
                                        height: '0',
                                        minWidth: '0',
                                        p: 0,
                                    }}
                                    onClick={() => {
                                        refetch();
                                        handleOpenModal();
                                    }}
                                >
                                    <Typography
                                        fontWeight="700"
                                        textTransform={'uppercase'}
                                        fontSize={'11px'}
                                    >
                                        View
                                    </Typography>
                                </Button>
                            )}
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '5px',
                            }}
                        >
                            <Box>
                                <Typography fontWeight="700" fontSize={'12px'}>
                                    {parseInt(
                                        productDataItems[currentIndex]?.StockMaster?.on_hand || '0'
                                    ).toFixed()}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>

                    {/* Next ETA */}
                    {isValidDate && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'start',
                                backgroundColor: '#F7F7F7',
                                width: '100%',
                                p: '12px 16px',
                                mt: '1rem',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    p: '0'
                                }}
                            >
                                <Typography
                                    fontSize={'11px'}
                                    textTransform={'uppercase'}
                                    fontWeight={'700'}
                                >
                                    NEXT ETA
                                </Typography>
                                <Tooltip title="Please note that the provided date is an estimate and may be subject to change. Please allow for delivery time.">
                                    <IconButton
                                        sx={{
                                            width: '24px',
                                            height: '24px',
                                            p: 0,
                                        }}
                                        color="inherit"
                                    >
                                        <HelpOutline sx={{ fontSize: '16px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Box>

                            <Box>
                                <Typography fontWeight="700" fontSize={'11px'} lineHeight={2}>
                                    {year === 1900 && month === 1 && day === 1
                                        ? 'To Be Confirmed'
                                        : `${day}.${month}.${year}`}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </Box>

                {/* Thumbnails List */}
                <Box
                    sx={{
                        mt: '20px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: 'auto',
                            cursor: 'pointer', // Changed to 'pointer' for better UX
                            overflowX: productDataItems?.length >= 6 ? 'auto' : 'hidden',
                            '&::-webkit-scrollbar': {
                                height: '8px',
                            },
                            '&::-webkit-scrollbar-track': {
                                backgroundColor: 'transparent',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: '#888',
                                borderRadius: '4px',
                                border: '2px solid transparent',
                            },
                            '&::-webkit-scrollbar-thumb:hover': {
                                backgroundColor: '#555',
                            },
                            scrollbarWidth: 'thin',
                            scrollbarColor: '#888 transparent',
                        }}
                    >
                        {productDataItems
                            .slice(0, more)
                            .map((product: any, index: number) => (
                                <Box
                                    key={index}
                                    sx={{
                                        cursor: 'pointer',
                                    }}
                                    onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        handleThumbnailClick(index, event);
                                    }}
                                >
                                    <img
                                        style={{
                                            height: '50px',
                                            width: '50px',
                                            padding: '5px',
                                            marginRight: '0',
                                            border: selectedImagePreviewIndex === index
                                                ? '1px solid black'
                                                : 'none',
                                            objectFit: 'cover',
                                        }}
                                        src={product?.Product?.primaryImageUrl}
                                        alt={`Thumbnail ${index + 1}`}
                                    />
                                </Box>
                            ))}
                    </Box>
                    {/* Show More / Show Less Button */}
                    {productDataItems?.length > (window.innerWidth > 600 ? 6 : 3) && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            {more === (window.innerWidth > 600 ? 6 : 3) ? (
                                <Button
                                    variant="contained"
                                    onClick={showMore}
                                    disableElevation
                                    sx={{
                                        borderRadius: '0px',
                                        height: '50px',
                                        width: '50px',
                                        background: '#000',
                                        color: '#FFF',
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    +{productDataItems.length - more}
                                </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    onClick={showLess}
                                    disableElevation
                                    sx={{
                                        borderRadius: '0px',
                                        height: '50px',
                                        width: '50px',
                                        background: '#000',
                                        color: '#FFF',
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    Less
                                </Button>
                            )}
                        </Box>
                    )}
                </Box>

                {/* Add to Cart and Download Image Buttons */}
                {isLogin && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', lg: 'row' },
                            gap: { xs: '10px', lg: '0' },
                            mt: 2,
                        }}
                    >
                        {/* Add to Cart Button */}
                        <Button
                            disableElevation
                            sx={{
                                width: { xs: '100%', lg: '40%' },
                                height: '60px',
                                borderRadius: 0,
                            }}
                            variant="contained"
                            onClick={() => {
                                const tradePrice = calculateTradePrice();
                                const itemWithQuantity = {
                                    ...currentSelectedItem,
                                    price: tradePrice,
                                };
                                addToCart(itemWithQuantity);
                                setCategoryQuantity('');
                            }}
                            disabled={
                                productDataItems[currentIndex]?.StockMaster?.on_hand === 0 ||
                                Number(categoryQuantity) >
                                productDataItems[currentIndex]?.StockMaster?.on_hand ||
                                Number(categoryQuantity) <= 0
                            }
                        >
                            Add to cart
                        </Button>

                        {/* Download Image Button */}
                        <Box
                            sx={{
                                mt: { xs: 2, lg: 0 },
                                ml: { xs: 0, lg: 2 },
                                width: { xs: '100%', lg: '40%' },
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Button
                                disableElevation
                                sx={{
                                    width: '100%',
                                    height: '60px',
                                    borderRadius: 0,
                                    background: '#000',
                                    color: '#FFF',
                                    border: '1px solid #000',
                                    textTransform: 'none',
                                    fontWeight: 'bold',
                                    '&:hover': {
                                        background: '#000', // Keep the same background on hover
                                        color: '#FFF', // Keep the same text color on hover
                                        border: '1px solid #000', // Keep the same border on hover
                                    },
                                }}
                                component="a"
                                href={displayedImage}
                                download={
                                    displayedImage
                                        ? `hertex-product-${productDataItems[currentIndex]?.Product?.sku}-image-${activeImageStep}.jpg`
                                        : 'hertex-product.jpg'
                                }
                            >
                                DOWNLOAD IMAGE
                            </Button>
                        </Box>
                    </Box>
                )}
            </Box>
            <StockRollModal
                modalIsOpen={modalIsOpen}
                handleCloseModal={handleCloseModal}
                stockRollData={stockRollData}
                isSuccess={isSuccess}
                isError={isError}
                isLoading={isLoading}
            />
        </Box>
    );
};

export default ProductSection;
