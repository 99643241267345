import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';

type FilterTypeElement =
    | string
    | { idx: number | string; [key: string]: any };

interface FilterProps {
    filterType: FilterTypeElement[];
    filteredOptions: Array<{ id: number } | string>;
    handleCheckboxChange: (
        elem: string | number,
        setList: React.Dispatch<React.SetStateAction<string[]>>,
        list: string[],
        paramKey: string
    ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    list: string[];
    setList: React.Dispatch<React.SetStateAction<string[]>>;
    keyName: string;
    paramKey: string;
}

const Filter = ({
                    filterType,
                    filteredOptions,
                    handleCheckboxChange,
                    list,
                    setList,
                    keyName,
                    paramKey,
                }: FilterProps) => {
    // Modified isItemFiltered function that properly handles empty filtered options
    const isItemFiltered = (value: string | number): boolean => {
        // If no filtered options, show all items
        if (!filteredOptions || filteredOptions.length === 0) return true;

        const valueStr = value.toString().toLowerCase();

        if (typeof filteredOptions[0] === 'string') {
            // For sizes and shapes - case insensitive comparison
            return filteredOptions
                .map(option => option.toString().toLowerCase())
                .includes(valueStr);
        } else {
            // For other filters that use { id: number }
            return filteredOptions.some(option =>
                typeof option === 'object' &&
                'id' in option &&
                option.id.toString() === valueStr
            );
        }
    };

    return (
        <Box sx={{ overflowY: 'auto', maxHeight: '20.5rem' }}>
            <FormGroup>
                {filterType
                    .slice()
                    .sort((a, b) => {
                        const aValue = typeof a === 'string' ? a : (a[keyName]?.toString() || '');
                        const bValue = typeof b === 'string' ? b : (b[keyName]?.toString() || '');
                        return aValue.localeCompare(bValue);
                    })
                    .map((elem, index) => {
                        let key: string | number;
                        let label: string;
                        let value: string;

                        if (typeof elem === 'string') {
                            // Handle string elements (sizes/shapes)
                            key = `${elem}-${index}`;
                            label = elem;
                            value = elem;  // Don't lowercase here
                        } else {
                            // Handle object elements
                            key = elem.idx;
                            label = elem[keyName] ? elem[keyName] : 'Unknown';
                            value = elem.idx.toString();
                        }

                        // Only render if item is in filtered options or no filters applied
                        if (!isItemFiltered(value)) return null;

                        return (
                            <FormControlLabel
                                key={key}
                                control={
                                    <Checkbox
                                        checked={list.includes(value)}
                                        onChange={handleCheckboxChange(
                                            value,
                                            setList,
                                            list,
                                            paramKey
                                        )}
                                    />
                                }
                                label={label}
                            />
                        );
                    })}
            </FormGroup>
        </Box>
    );
};

export default Filter;