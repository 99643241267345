import {
    Box,
    Checkbox,
    Divider,
    FormControlLabel,
    FormGroup,
    Typography,
} from '@mui/material';
import { ChangeEvent } from 'react';

// Update the interface to specify the correct event type
interface ApplicationSelectionProps {
    availableApplications: string[];
    handleCheckboxChange: (
        elem: string | number,
        setList: React.Dispatch<React.SetStateAction<string[]>>,
        list: string[],
        paramKey: string
    ) => (event: ChangeEvent<HTMLInputElement>) => void;
    applicationsList: string[];
    setApplicationsList: React.Dispatch<React.SetStateAction<string[]>>;
}

const ApplicationSelection = ({
                                  availableApplications,
                                  handleCheckboxChange,
                                  applicationsList,
                                  setApplicationsList,
                              }: ApplicationSelectionProps) => {
    return (
        <Box>
            <Divider sx={{ backgroundColor: '#000000', mb: '1rem' }} />
            <Typography fontWeight='bold' fontSize='20px' mb={2}>
                Applications
            </Typography>

            <Box sx={{ overflowY: 'auto', maxHeight: '20.5rem' }}>
                <FormGroup>
                    {availableApplications
                        ?.sort()
                        .map((elem: string) => {
                            // Create the event handler here
                            const handler = handleCheckboxChange(
                                elem,
                                setApplicationsList,
                                applicationsList,
                                'application'
                            );

                            return (
                                <FormControlLabel
                                    key={elem}
                                    control={
                                        <Checkbox
                                            checked={applicationsList.includes(elem)}
                                            onChange={handler}  // Pass the handler directly
                                        />
                                    }
                                    label={elem}
                                />
                            );
                        })}
                </FormGroup>
            </Box>
        </Box>
    );
};

export default ApplicationSelection;