import PageLayout from '../../components/shared/layout/PageLayout';
import CarouselBanner from '../../components/shared/banner/CarouselBanner';
import { storeData } from './companyStoreData/companyLocationData';
import AccordionComponent from '../../components/shared/accordion/AccordionComponent';
import CompanyTextHeader from '../../components/shared/textHeader/companyText';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import storehero1 from '../../assets/store-locator/StoreHero1.jpg';
import storehero2 from '../../assets/store-locator/StoreHero2.jpg';
import storehero3 from '../../assets/store-locator/StoreHero3.jpg';
import storehero4 from '../../assets/store-locator/StoreHero4.jpg';
import { captureAnalytics } from '../../utilities/Analytics';

export const carouselImages = [
    {
        url: storehero1,
    },
    {
        url: storehero2,
    },
    {
        url: storehero3,
    },
    {
        url: storehero4,
    },
];

const StoreLocatorPage = () => {
    const [expanded, setExpanded] = useState<string | false>(false);
    const location = useLocation();

    const handleChange =
        (panel: string) =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        captureAnalytics('pageview', location.pathname, 'Store Locator');
    }, [location.pathname]);
    return (
        <PageLayout>
            <CarouselBanner
                heroImages={carouselImages}
                displayOption="carousel"
            />

            <CompanyTextHeader
                heading='Find a store near you.'
                isCentered={false}
                width='70%'
                marginTop={10}
                marginBottom={0}
                isColor={true}
                isWidthChange={true}
            />

            {storeData.map((region, index) => (
                <AccordionComponent
                    key={index}
                    panelNumber={index}
                    expanded={expanded}
                    handleChange={handleChange}
                    title={region.title} // Main title of the region
                    stores={region.stores}
                    displayFAQs={false}
                    isTop={'-50px'}
                />
            ))}
        </PageLayout>
    );
};

export default StoreLocatorPage;
