import PageLayout from '../layout/PageLayout';
import { useQuery } from 'react-query';
import { useParams, useLocation } from 'react-router-dom';
import {
  apiGetCollectionPageData,
  apiGetDesignsInCollection,
} from '../../../api/apiCollections';
import LoadingScreen from '../loadingScreen/LoadingScreen';
import TextHeader from '../textHeader/TextHeader';
import Gallery from '../gallery/Gallery';
import { useEffect, useState } from 'react';
import MissingPage from '../errorMessages/MissingPage';
import DesignsInCollectionGrid from '../grid/DesignsInCollectionGrid';
import CarouselBanner from '../banner/CarouselBanner';
import { captureAnalytics } from '../../../utilities/Analytics';
import FilterComponent from '../filter/FilterComponent'; // Import the filter component

function CollectionTemplate() {
  const { item_group, application, collection } = useParams();
  const location = useLocation();

  const [applicationsList, setApplicationsList] = useState<string[]>([]);
  const [colourList, setColourList] = useState<number[]>([]);
  const [designList, setDesignList] = useState<number[]>([]);
  const [typeList, setTypeList] = useState<number[]>([]);

  const argumentDataStructure = {
    category: {
      application: application,
      item_group: item_group,
      collection: collection,
    },
  };

  const designFilters = {
    filter: {
      item_group: item_group,
      application: application,
      range: collection,
    },
    off_setter: {
      start: 0,
      end: 100,
    },
  };

  const {
    isSuccess,
    isError,
    isLoading,
    data: collectionData,
  } = useQuery<any, Error>([`collection-${collection}`], () =>
      apiGetCollectionPageData(argumentDataStructure)
  );

  const { data: designsData } = useQuery(
      [`collection-designs-${collection}`],
      () => apiGetDesignsInCollection(designFilters)
  );

  // Step 2.1: Extract filter parameters from URL
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const applicationParams = searchParams.getAll('application');
    const colourParams = searchParams.getAll('colours');
    const designParams = searchParams.getAll('design_style');
    const typeParams = searchParams.getAll('types');

    // Initialize state using the URL parameters
    setApplicationsList(applicationParams);
    setColourList(colourParams.map(Number)); // Convert string to number
    setDesignList(designParams.map(Number)); // Convert string to number
    setTypeList(typeParams.map(Number)); // Convert string to number
  }, [location.search]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    captureAnalytics(
        'pageview',
        location.pathname,
        `Collection - ${collection}`
    );
  }, [location.pathname, collection]);

  return (
      <PageLayout>
        {isError && <MissingPage />}
        {isLoading ? (
            <LoadingScreen />
        ) : (
            <>
              {isSuccess && (
                  <>
                    <CarouselBanner
                        heroImages={collectionData?.section_trade_hero}
                        heroLink={collectionData?.section_trade_hero_link}
                        heroVideo={collectionData?.section_trade_hero_video ? {
                          video_url: collectionData.section_trade_hero_video.video_url,
                          thumbnail_url: collectionData.section_trade_hero_video.thumbnail_url
                        } : undefined}
                        videoLink={collectionData?.section_trade_video_link}
                        promoImages={collectionData?.section_trade_promo}
                        promoLink={collectionData?.section_trade_promo_link}
                        displayOption={collectionData?.trade_display_option}
                    />
                    <TextHeader
                        heading={collectionData?.page_title}
                        content={collectionData?.description}
                        isCentered={false}
                        width="70%"
                        marginTop={10}
                        marginBottom={5}
                    />



                    <Gallery
                        images={collectionData?.section_gallery}
                        galleryLayout={'masonry'}
                        columns={2}
                        title={'title'}
                        secondaryText={'2bd'}
                        hasTitle={false}
                        hasSecondaryText={false}
                    />

                    <DesignsInCollectionGrid
                        data={designsData}
                        marginTop="5rem"
                        marginBottom="5rem"
                    />




                  </>
              )}
            </>
        )}
      </PageLayout>
  );
}

export default CollectionTemplate;
