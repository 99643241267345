import { useLayoutEffect, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams, useLocation } from 'react-router-dom';
import {
    apiGetApplicationNamesByItemGroup,
    apiGetCategoryMainPageData,
} from '../../../api/apiCategories';
import PageLayout from '../../../components/shared/layout/PageLayout';
import CarouselBanner from '../../../components/shared/banner/CarouselBanner';
import TextHeader from '../../../components/shared/textHeader/TextHeader';
import NewArrivalsGrid from '../../../components/shared/grid/NewArrivalsGrid';
import FilterComponent from '../filter/FilterComponent';
import LoadingScreen from '../../../components/shared/loadingScreen/LoadingScreen';
import FeaturedGallery from '../../../components/shared/gallery/FeaturedGallery';
import MissingPage from '../errorMessages/MissingPage';
import ItemGrid from '../grid/ItemGrid';
import HomewareCategoryGrid from '../grid/HomeWareCategoryGrid';
import { captureAnalytics } from '../../../utilities/Analytics';

function CategoryTemplate() {
    const { item_group } = useParams();
    const [filteredApplicationCards, setFilteredApplicationCards] = useState<string[]>([]);
    const location = useLocation();

    const itemGroupData = {
        category: { item_group: item_group },
    };

    const {
        isSuccess,
        isError,
        isLoading,
        data: categoryData,
    } = useQuery<any, Error>(
        [`main-category-${item_group}`],
        () => apiGetCategoryMainPageData(itemGroupData),
        { staleTime: 600000 } // 10 minutes
    );

    const { data: applicationNames } = useQuery<any, Error>(
        [`application-names-${item_group}`],
        () => apiGetApplicationNamesByItemGroup(item_group || ''),
        { staleTime: 600000 }
    );

    // **1. Filter Application Cards (Preserving Typo)**
    useEffect(() => {
        if (isSuccess && categoryData && applicationNames?.appliactions) { // Preserved typo
            const filtered = categoryData.section_application_cards.filter((card: any) =>
                applicationNames.appliactions.includes(card.application_name)
            );
            console.log('Filtered Application Cards:', filtered); // Debugging line
            setFilteredApplicationCards(filtered);
        }
    }, [isSuccess, categoryData, applicationNames]);

    // **2. Save Scroll Position Before Navigation**
    useEffect(() => {
        return () => {
            sessionStorage.setItem(`scroll-position-${location.pathname}`, window.scrollY.toString());
        };
    }, [location.pathname]);

    // **3. Restore Scroll Position Immediately Upon Mount**
    useLayoutEffect(() => {
        const savedPosition = sessionStorage.getItem(`scroll-position-${location.pathname}`);
        if (savedPosition) {
            window.scrollTo(0, parseInt(savedPosition, 10));
            sessionStorage.removeItem(`scroll-position-${location.pathname}`); // Optional: Clean up
        } else {
            window.scrollTo(0, 0);
        }
    }, [location.pathname, item_group]);

    // **4. Analytics Capture**
    useEffect(() => {
        if (isSuccess && categoryData) {
            const title = categoryData.page_title;
            captureAnalytics('pageview', location.pathname, title);
        }
    }, [location.pathname, item_group, isSuccess, categoryData]);

    return (
        <PageLayout>
            {isLoading && <LoadingScreen />}
            {isError && <MissingPage />}
            {isSuccess && (
                <>
                    <CarouselBanner
                        heroImages={categoryData?.section_trade_hero}
                        heroLink={categoryData?.section_trade_hero_link}
                        heroVideo={categoryData?.section_trade_hero_video ? {
                            video_url: categoryData.section_trade_hero_video.video_url,
                            thumbnail_url: categoryData.section_trade_hero_video.thumbnail_url
                        } : undefined}
                        videoLink={categoryData?.section_trade_video_link}
                        promoImages={categoryData?.section_trade_promo}
                        promoLink={categoryData?.section_trade_promo_link}
                        displayOption={categoryData?.trade_display_option}
                    />

                    <TextHeader
                        heading={categoryData?.page_title}
                        content={categoryData?.description}
                        isCentered={false}
                        width='70%'
                        marginTop={10}
                        marginBottom={5}
                    />
                    {item_group === '000' ? (
                        <HomewareCategoryGrid
                            data={categoryData?.section_homeware_landing_categories}
                            spacingValue={4}
                            marginTop={5}
                            marginBottom={5}
                        />
                    ) : (
                        <ItemGrid
                            data={filteredApplicationCards}
                            hasTitles={false}
                            hasButtons={false}
                            hasBackgroundText={true}
                            spacingValue={4}
                            hasCollectionLinks={false}
                            marginTop={5}
                            marginBottom={5}
                        />
                    )}
                    <FeaturedGallery
                        images={categoryData?.section_featured_collection}
                        hasVideo={false}
                        invert={false}
                        marginTop={5}
                        marginBottom={5}
                        title={categoryData?.featured_collection_title?.collection_name}
                        description={categoryData?.featured_collection_description}
                        itemGroup={categoryData?.featured_collection_title?.item_group_code}
                        application={categoryData?.featured_collection_title?.application_name}
                        collection={categoryData?.featured_collection_title?.collection_name}
                    />

                    <NewArrivalsGrid
                        header='New arrivals'
                        data={categoryData?.section_new_arrivals}
                        hasTitles={true}
                        hasButtons={true}
                        hasBackgroundText={false}
                        spacingValue={4}
                        marginTop={'5rem'}
                        marginBottom={'5rem'}
                    />
                    {item_group !== '000' && (
                        <FilterComponent
                            itemGroup={item_group}
                            title={categoryData?.page_title}
                            initialApplications={[]}
                        />
                    )}
                </>
            )}
        </PageLayout>
    );
}

export default CategoryTemplate;
