// FilterComponent.tsx

import React, { useEffect, useState, useRef } from 'react';
import { apiFilterProducts } from '../../../api/apiMultiFilter';
import ProductGrid from './components/ProductGrid';
import FilterSelection from './components/FilterSelection';
import ApplicationSelection from './components/ApplicationSelection';
import ClearFilters from './components/ClearFilters';
import { Grid, Box, Typography, Container, Button } from '@mui/material';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

interface StockMaster {
  web_colour: string;
  web_type: string;
}

interface ProductData {
  StockMaster: StockMaster;
}

// Define interfaces for API response
interface FilterData {
  applications: string[];
  web_colours: string[];
  web_designs_style: string[];
  webtypes: string[];
  brands: string[];
  materials: { material: string; idx: number }[];
  standards: { standards: string; idx: number }[]; // Added idx
  web_sizes: string[];
  total_items_count: number;
}

interface ApiResponse {
  filtered_colours?: { id: number }[];
  filtered_designs?: { id: number }[];
  filtered_types?: { id: number }[];
  filtered_brands?: { id: number }[];
  filtered_materials?: { id: number }[];
  filtered_standards?: { id: number }[];
  filtered_sizes?: string[];
  filtered_shapes?: string[];  // Add this if missing
  web_colours: { colours: string; idx: number }[];
  web_designs_style: { design_style: string; idx: number }[];
  webtypes: { types: string; idx: number }[];
  brands: { brand: string; idx: number }[];
  materials: { material: string; idx: number }[];
  standards: { standards: string; idx: number }[];
  web_sizes: string[];
  webshapes?: string[];  // Add this if missing
  applications: string[];
  total_items_count: number;
}


interface FilterMutationRequest {
  filter: {
    item_group: string;
  };
  off_setter: {
    start: number;
    end: number;
  };
  required_fields: {
    item_group: string;
    Applications: string[];
    WebTypes: number[];
    WebColors: number[];
    WebDesignStyles: number[];
    WebBrands: number[];
    WebMaterials: number[];
    WebStandards: number[];
    WebSizes: string[];
    WebShapes: string[];  // Add this
  };
}

type ApplicationType = 'curtaining' | 'dual purpose' | 'upholstery';

const getDefaultApplications = (pathname: string): string[] => {
  const pathParts = pathname.split('/');
  const applicationIndex = pathParts.indexOf('application');

  if (applicationIndex === -1) return [];

  const applicationPath = decodeURIComponent(pathParts[applicationIndex + 1]);

  // Map of path values to their corresponding default applications
  const applicationDefaults: Record<string, string[]> = {
    'curtaining': ['Curtaining', 'Dual Purpose'],
    'dual purpose': ['Dual Purpose'],
    'upholstery': ['Upholstery', 'Dual Purpose']
  };

  return applicationDefaults[applicationPath.toLowerCase()] || [];
};

function FilterComponent({ itemGroup, title }: any) {
  const navigate = useNavigate();
  const location = useLocation();

  const [defaultApplications, setDefaultApplications] = useState<string[]>([]);


  // Application states
  const [availableApplications, setAvailableApplications] = useState<string[]>([]);
  const [applicationsList, setApplicationsList] = useState<string[]>([]);

// Available option states
  const [availableColours, setAvailableColours] = useState<{colours: string, idx: number}[]>([]);
  const [availableDesigns, setAvailableDesigns] = useState<{design_style: string, idx: number}[]>([]);
  const [availableTypes, setAvailableTypes] = useState<{types: string, idx: number}[]>([]);
  const [availableBrands, setAvailableBrands] = useState<{brand: string, idx: number}[]>([]);
  const [availableMaterials, setAvailableMaterials] = useState<{material: string, idx: number}[]>([]);
  const [availableStandards, setAvailableStandards] = useState<{standards: string, idx: number}[]>([]);
  const [availableSizes, setAvailableSizes] = useState<string[]>([]);
  const [availableShapes, setAvailableShapes] = useState<string[]>([]);

// Selected value states
  const [colourList, setColourList] = useState<string[]>([]);
  const [designList, setDesignList] = useState<string[]>([]);
  const [typesList, setTypesList] = useState<string[]>([]);
  const [brandList, setBrandList] = useState<string[]>([]);
  const [materialList, setMaterialList] = useState<string[]>([]);
  const [standardsList, setStandardsList] = useState<string[]>([]);
  const [sizeList, setSizeList] = useState<string[]>([]);
  const [shapeList, setShapeList] = useState<string[]>([]);

// Filtered options states
  const [filteredColours, setFilteredColours] = useState<{id: number}[]>([]);
  const [filteredDesigns, setFilteredDesigns] = useState<{id: number}[]>([]);
  const [filteredTypes, setFilteredTypes] = useState<{id: number}[]>([]);
  const [filteredBrands, setFilteredBrands] = useState<{id: number}[]>([]);
  const [filteredMaterials, setFilteredMaterials] = useState<{id: number}[]>([]);
  const [filteredStandards, setFilteredStandards] = useState<{id: number}[]>([]);
  const [filteredSizes, setFilteredSizes] = useState<string[]>([]);
  const [filteredShapes, setFilteredShapes] = useState<string[]>([]);

// Pagination and other states
  const [productItemCount, setProductItemCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const itemsPerPage = 9;

  const anchorRef = useRef<HTMLDivElement>(null);

  const getProductsMutation = useMutation<ApiResponse[], Error, FilterMutationRequest>(
      (filters) => apiFilterProducts(filters),
      {
        onSuccess: (data) => {
          const lastItem = data[data.length - 1] as ApiResponse;

          // Set application options
          setAvailableApplications(lastItem?.applications || []);

          // Always process and set sizes and shapes first, regardless of application selection
          const webSizes = Array.isArray(lastItem?.web_sizes) ? lastItem.web_sizes : [];
          const processedSizes = webSizes
              .filter(size => size && size.trim() !== "-" && size.trim() !== "")
              .sort();

          const webShapes = lastItem?.webshapes || [];
          const processedShapes = webShapes
              .filter(shape => shape && shape.trim() !== '-' && shape.trim() !== '')
              .sort();

          // Always set available and filtered sizes/shapes
          setAvailableSizes(processedSizes);
          setFilteredSizes(lastItem?.filtered_sizes || processedSizes);

          setAvailableShapes(processedShapes);
          setFilteredShapes(lastItem?.filtered_shapes || processedShapes);

          // Set other available options
          setAvailableColours(lastItem?.web_colours || []);
          setAvailableDesigns(lastItem?.web_designs_style || []);
          setAvailableTypes(lastItem?.webtypes || []);
          setAvailableBrands(lastItem?.brands || []);
          setAvailableMaterials(
              lastItem?.materials?.filter(material => material.material !== "-") || []
          );
          setAvailableStandards(
              lastItem?.standards?.filter(standard => standard.standards !== "-") || []
          );

          // Check if we're dealing with a single bench item
          const isSingleBenchItem = lastItem.total_items_count === 1 &&
              applicationsList.length === 1 &&
              applicationsList.includes('Bench');

          if (isSingleBenchItem) {
            const singleItem = data[0] as unknown as ProductData;  // Changed from validProducts to data

            // Process colors for bench
            const itemColors = singleItem.StockMaster?.web_colour?.split('|').map((c: string) => c.trim()) || [];
            const filteredColors = lastItem.web_colours.filter((color: { colours: string }) =>
                itemColors.includes(color.colours)
            ).map((c: { idx: number }) => ({ id: c.idx }));

            // Process types for bench
            const itemTypes = singleItem.StockMaster?.web_type?.split('|').map((t: string) => t.trim()) || [];
            const filteredTypes = lastItem.webtypes.filter((type: { types: string }) =>
                itemTypes.includes(type.types)
            ).map((t: { idx: number }) => ({ id: t.idx }));

            // Set the filtered states
            setFilteredColours(filteredColors);
            setFilteredTypes(filteredTypes);
            setFilteredDesigns(lastItem.filtered_designs || []);
            setFilteredBrands(lastItem.filtered_brands || []);
            setFilteredMaterials(lastItem.filtered_materials || []);
            setFilteredStandards(lastItem.filtered_standards || []);
          } else {
            // Handle filtered options based on application selection for non-bench cases
            if (lastItem?.filtered_colours) {
              setFilteredColours(lastItem.filtered_colours);
              setFilteredDesigns(lastItem.filtered_designs || []);
              setFilteredTypes(lastItem.filtered_types || []);
              setFilteredBrands(lastItem.filtered_brands || []);
              setFilteredMaterials(lastItem.filtered_materials || []);
              setFilteredStandards(lastItem.filtered_standards || []);
            } else {
              // If no filtered data, use available options as filtered options
              setFilteredColours(
                  lastItem?.web_colours?.map(c => ({ id: c.idx })) || []
              );
              setFilteredDesigns(
                  lastItem?.web_designs_style?.map(d => ({ id: d.idx })) || []
              );
              setFilteredTypes(
                  lastItem?.webtypes?.map(t => ({ id: t.idx })) || []
              );
              setFilteredBrands(
                  lastItem?.brands?.map(b => ({ id: b.idx })) || []
              );
              setFilteredMaterials(
                  lastItem?.materials?.filter(m => m.material !== "-")
                      .map(m => ({ id: m.idx })) || []
              );
              setFilteredStandards(
                  lastItem?.standards?.filter(s => s.standards !== "-")
                      .map(s => ({ id: s.idx })) || []
              );
            }
          }

          // Use total_items_count directly from lastItem
          setProductItemCount(lastItem.total_items_count);
          setTotalPages(Math.ceil(lastItem.total_items_count / itemsPerPage));
        },
        onError: (error: Error) => {
          console.error("Error fetching products:", error);
        }
      }
  );

  // Replace the existing handleCheckboxChange function with this:
  const handleCheckboxChange = (
      elem: string | number,  // Change to allow both string and number
      setList: React.Dispatch<React.SetStateAction<string[]>>,
      list: string[],
      paramKey: string
  ) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    const elemStr = elem.toString();  // Remove toLowerCase()

    let updatedList: string[];
    if (isChecked) {
      updatedList = [...list, elemStr];  // Remove includes check
      setList(updatedList);
    } else {
      updatedList = list.filter((item) => item !== elemStr);
      setList(updatedList);
    }
  };

  const applyFilters = () => {
    const currentParams = new URLSearchParams();

    // Append all selected filters to the URL parameters
    applicationsList.forEach((app) => currentParams.append('application', app));
    colourList.forEach((col) => currentParams.append('colours', col));
    designList.forEach((design) => currentParams.append('design_style', design));
    typesList.forEach((type) => currentParams.append('types', type));
    brandList.forEach((brand) => currentParams.append('brand', brand));
    materialList.forEach((material) => currentParams.append('material', material));
    standardsList.forEach((std) => currentParams.append('standards', std));
    sizeList.forEach((sz) => currentParams.append('sizes', sz));
    shapeList.forEach((shape) => currentParams.append('shapes', shape));

    // Reset pagination parameters
    currentParams.set('page', '1');
    currentParams.set('startoffset', '0');
    currentParams.set('endoffset', itemsPerPage.toString());

    // Update the URL with the new parameters
    navigate(`${location.pathname}?${currentParams.toString()}`, { replace: true });

    // Apply all filters at once
    getProductsMutation.mutate({
      filter: {
        item_group: itemGroup,
      },
      off_setter: {
        start: 0,
        end: itemsPerPage,
      },
      required_fields: {
        item_group: itemGroup,
        Applications: applicationsList,
        WebTypes: typesList.map(Number),
        WebColors: colourList.map(Number),
        WebDesignStyles: designList.map(Number),
        WebBrands: brandList.map(Number),
        WebMaterials: materialList.map(Number),
        WebStandards: standardsList.map(Number),
        WebSizes: sizeList.map(sz => sz.toLowerCase()),
        WebShapes: shapeList.map(sh => sh.toLowerCase())
      },
    });

    // Update the current page state and scroll
    setCurrentPage(1);
    if (anchorRef.current) {
      window.scrollTo({
        top: anchorRef.current.offsetTop - 65,
        behavior: 'smooth',
      });
    }
  };

  const getInitialData = () => {
    const currentParams = new URLSearchParams(location.search);

    // Get all parameters from URL
    const applicationParams = currentParams.getAll('application');
    const colourParams = currentParams.getAll('colours');
    const designParams = currentParams.getAll('design_style');
    const typeParams = currentParams.getAll('types');
    const brandParams = currentParams.getAll('brand');
    const materialParams = currentParams.getAll('material');
    const standardsParams = currentParams.getAll('standards');
    const sizeParams = currentParams.getAll('sizes');
    const shapeParams = currentParams.getAll('shapes');

    // If no application params but we have defaults, use the defaults
    const applicationsToUse = applicationParams.length > 0
        ? applicationParams
        : defaultApplications;

    // Set states based on URL parameters or defaults
    setApplicationsList(applicationsToUse);
    setColourList(colourParams);
    setDesignList(designParams);
    setTypesList(typeParams);
    setBrandList(brandParams);
    setMaterialList(materialParams);
    setStandardsList(standardsParams);
    setSizeList(sizeParams.map(sz => sz.toLowerCase()));
    setShapeList(shapeParams.map(sh => sh.toLowerCase()));

    // Get pagination parameters
    const page = parseInt(currentParams.get('page') || '1');
    const startOffset = parseInt(currentParams.get('startoffset') || '0');
    const endOffset = parseInt(currentParams.get('endoffset') || itemsPerPage.toString());

    // Fetch data with all current filters
    getProductsMutation.mutate({
      filter: {
        item_group: itemGroup,
      },
      off_setter: {
        start: startOffset,
        end: endOffset,
      },
      required_fields: {
        item_group: itemGroup,
        Applications: applicationsToUse,
        WebTypes: typeParams.map(Number),
        WebColors: colourParams.map(Number),
        WebDesignStyles: designParams.map(Number),
        WebBrands: brandParams.map(Number),
        WebMaterials: materialParams.map(Number),
        WebStandards: standardsParams.map(Number),
        WebSizes: sizeParams.map(sz => sz.toLowerCase()),
        WebShapes: shapeParams.map(sh => sh.toLowerCase())
      },
    });

    setCurrentPage(page);
  };

  const clearFilters = () => {
    // Get current application from URL
    const currentParams = new URLSearchParams(location.search);
    const currentApplication = currentParams.get('application');

    // For fabric-related applications (curtaining, upholstery, dual purpose),
    // use default applications. For all other applications, keep the current one
    const isFabricApplication = currentApplication &&
        ['Curtaining', 'Upholstery', 'Dual Purpose'].includes(currentApplication);

    const applicationsToKeep = isFabricApplication
        ? defaultApplications
        : currentApplication
            ? [currentApplication]
            : [];

    // Update application list state
    setApplicationsList(applicationsToKeep);

    // Clear all other filters
    setColourList([]);
    setDesignList([]);
    setTypesList([]);
    setBrandList([]);
    setMaterialList([]);
    setStandardsList([]);
    setSizeList([]);
    setShapeList([]);

    // Update URL to include the preserved applications
    const params = new URLSearchParams();
    applicationsToKeep.forEach(app => params.append('application', app));
    navigate(`${location.pathname}${applicationsToKeep.length ? '?' + params.toString() : ''}`, { replace: true });

    // Reset pagination
    setCurrentPage(1);

    // Scroll to top
    if (anchorRef.current) {
      window.scrollTo({
        top: anchorRef.current.offsetTop - 65,
        behavior: 'smooth',
      });
    }

    // Re-fetch data with preserved applications
    getProductsMutation.mutate({
      filter: {
        item_group: itemGroup,
      },
      off_setter: {
        start: 0,
        end: itemsPerPage,
      },
      required_fields: {
        item_group: itemGroup,
        Applications: applicationsToKeep,
        WebTypes: [],
        WebColors: [],
        WebDesignStyles: [],
        WebBrands: [],
        WebMaterials: [],
        WebStandards: [],
        WebSizes: [],
        WebShapes: [],
      },
    });
  };

  const updateOffsetParams = (params: { [key: string]: string }): void => {
    const currentParams = new URLSearchParams(location.search);

    Object.entries(params).forEach(([key, value]) => {
      currentParams.set(key, value);
    });

    navigate(`${location.pathname}?${currentParams.toString()}`, { replace: true });
  };

  // Scroll to the top of the FilterComponent
  const scrollToFilterComponent = () => {
    if (anchorRef.current) {
      window.scrollTo({
        top: anchorRef.current.offsetTop - 65, // Adjust the offset as needed
        behavior: 'smooth',
      });
    }
  };

  // Pagination functions adjusted to scroll to the top of FilterComponent
  const nextPage = () => {
    if (currentPage === totalPages) {
      return;
    } else {
      const nextPageNum = currentPage + 1;
      const startOffset = (nextPageNum - 1) * itemsPerPage;
      const endOffset = startOffset + itemsPerPage;

      updateOffsetParams({
        page: nextPageNum.toString(),
        startoffset: startOffset.toString(),
        endoffset: endOffset.toString(),
      });

      setCurrentPage(nextPageNum);

      // Scroll to the top of the FilterComponent
      scrollToFilterComponent();
    }
  };

  const previousPage = () => {
    if (currentPage === 1) {
      return;
    } else {
      const prevPageNum = currentPage - 1;
      const startOffset = (prevPageNum - 1) * itemsPerPage;
      const endOffset = startOffset + itemsPerPage;

      updateOffsetParams({
        page: prevPageNum.toString(),
        startoffset: startOffset.toString(),
        endoffset: endOffset.toString(),
      });

      setCurrentPage(prevPageNum);

      // Scroll to the top of the FilterComponent
      scrollToFilterComponent();
    }
  };

  const lastPage = () => {
    if (currentPage === totalPages) {
      return;
    } else {
      const lastPageNumber = totalPages;
      const startOffset = (lastPageNumber - 1) * itemsPerPage;
      const endOffset = startOffset + itemsPerPage;

      updateOffsetParams({
        page: lastPageNumber.toString(),
        startoffset: startOffset.toString(),
        endoffset: endOffset.toString(),
      });

      setCurrentPage(lastPageNumber);

      // Scroll to the top of the FilterComponent
      scrollToFilterComponent();
    }
  };

  const firstPage = () => {
    if (currentPage === 1) {
      return;
    } else {
      const firstPageNumber = 1;
      const startOffset = 0;
      const endOffset = itemsPerPage;

      updateOffsetParams({
        page: firstPageNumber.toString(),
        startoffset: startOffset.toString(),
        endoffset: endOffset.toString(),
      });

      setCurrentPage(firstPageNumber);

      // Scroll to the top of the FilterComponent
      scrollToFilterComponent();
    }
  };

  // Run getInitialData when the component mounts or when location.search changes
  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]); // Listen for changes in URL parameters

  // Add this useEffect
  useEffect(() => {
    const defaults = getDefaultApplications(location.pathname);
    setDefaultApplications(defaults);

    // If this is initial load (no search params) and we have defaults, set them
    if (!location.search && defaults.length > 0) {
      setApplicationsList(defaults);

      // Update URL to include default applications
      const params = new URLSearchParams();
      defaults.forEach((app: string) => params.append('application', app));
      navigate(`${location.pathname}?${params.toString()}`, { replace: true });

      // Fetch products with default applications
      getProductsMutation.mutate({
        filter: {
          item_group: itemGroup,
        },
        off_setter: {
          start: 0,
          end: itemsPerPage,
        },
        required_fields: {
          item_group: itemGroup,
          Applications: defaults,
          WebTypes: [],
          WebColors: [],
          WebDesignStyles: [],
          WebBrands: [],
          WebMaterials: [],
          WebStandards: [],
          WebSizes: [],
          WebShapes: []
        },
      });
    }
  }, [
    location.pathname,
    navigate,
    setApplicationsList,
    setDefaultApplications,
    itemGroup,
    getProductsMutation
  ]); // Add all dependencies

  return (
      <Container maxWidth="xl">
        <Typography
            variant="h3"
            mb={1}
            mt={10}
            ml={2}
            fontWeight={400}
            fontSize={'40px'}
            textTransform={'capitalize'}
        >
          {`Discover - ${title}`}
        </Typography>
        <Typography fontWeight={400} fontSize="18px" mb={'-4rem'} ml={2}>
          Our inventory features thousands of designs. Use the selection bar below to find exactly what you’re looking for.
        </Typography>

        <Box ref={anchorRef}>
          <Grid
              container
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', lg: 'row' },
                height: 'auto',
                width: '100%',
                padding: '1rem',
                m: '4rem 0',
              }}
          >
            <Box
                sx={{
                  flex: { xs: 12, lg: 4 },
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  mr: { xs: 0, lg: 5 },
                }}
            >
              <ClearFilters clearFilters={clearFilters} />
              {availableApplications.length > 1 && (
                  <ApplicationSelection
                      availableApplications={availableApplications}
                      handleCheckboxChange={handleCheckboxChange}
                      applicationsList={applicationsList}
                      setApplicationsList={setApplicationsList}
                  />
              )}

              <FilterSelection
                  // Existing available options
                  availableColours={availableColours}
                  availableDesigns={availableDesigns}
                  availableTypes={availableTypes}
                  availableBrands={availableBrands}
                  availableMaterials={availableMaterials}
                  availableStandards={availableStandards}
                  availableSizes={availableSizes}
                  availableShapes={availableShapes}

                  // All filtered options
                  filteredColours={filteredColours}
                  filteredDesigns={filteredDesigns}
                  filteredTypes={filteredTypes}
                  filteredBrands={filteredBrands}
                  filteredMaterials={filteredMaterials}
                  filteredStandards={filteredStandards}
                  filteredSizes={filteredSizes}
                  filteredShapes={filteredShapes}

                  // Selected values
                  colourList={colourList}
                  designList={designList}
                  typesList={typesList}
                  brandList={brandList}
                  materialList={materialList}
                  standardsList={standardsList}
                  sizeList={sizeList}
                  shapeList={shapeList}

                  // State setters
                  setColourList={setColourList}
                  setDesignList={setDesignList}
                  setTypesList={setTypesList}
                  setBrandList={setBrandList}
                  setMaterialList={setMaterialList}
                  setStandardsList={setStandardsList}
                  setSizeList={setSizeList}
                  setShapeList={setShapeList}

                  // Other props
                  handleCheckboxChange={handleCheckboxChange}
                  applicationsList={applicationsList}
                  anchorRef={anchorRef}
                  applyFilters={applyFilters}
                  itemGroup={itemGroup}
              />
            </Box>
            <Box
                flex={{ xs: 12, lg: 8 }}
                sx={{
                  height: '100%',
                  flexDirection: 'column',
                }}
            >
              {getProductsMutation?.data?.length === 1 &&
              (getProductsMutation?.data[0] as any)?.total_items_count === 0 ? (
                  <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        width: '100%',
                        height: '80vh',
                      }}
                  >
                    <Typography fontSize={'1.5rem'} fontWeight={'bold'}>
                      No items found
                    </Typography>
                    <Typography fontSize={'1.5rem'} fontWeight={'bold'}>
                      Please try a different selection
                    </Typography>
                  </Box>
              ) : (
                  <ProductGrid
                      productItemCount={productItemCount}
                      getProductsMutation={getProductsMutation}
                      totalPages={totalPages}
                      currentPage={currentPage}
                      previousPage={previousPage}
                      nextPage={nextPage}
                      lastPage={lastPage}
                      firstPage={firstPage}
                      anchorRef={anchorRef}
                  />
              )}
            </Box>
          </Grid>
        </Box>
      </Container>
  );

}

export default FilterComponent;
