// FilterSelection.tsx

import Filter from './Filter';
import { Box, Button, Divider, Typography, useMediaQuery } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useEffect, useState } from 'react';

interface FilterCategory {
    title: string;
    available: any[];      // Keep for available options
    filtered: any[];       // Add for filtered options
    list: string[];
    setList: React.Dispatch<React.SetStateAction<string[]>>;
    keyName: string;
    paramKey: string;
}

const FilterSelection = ({
                             // Available options
                             availableColours,
                             availableDesigns,
                             availableTypes,
                             availableBrands,
                             availableMaterials,
                             availableStandards,
                             availableSizes,
                             availableShapes,

                             // Filtered options
                             filteredColours,
                             filteredDesigns,
                             filteredTypes,
                             filteredBrands,
                             filteredMaterials,
                             filteredStandards,
                             filteredSizes,
                             filteredShapes,

                             // Selected values
                             colourList,
                             designList,
                             typesList,
                             brandList,
                             materialList,
                             standardsList,
                             sizeList,
                             shapeList,

                             // State setters
                             setColourList,
                             setDesignList,
                             setTypesList,
                             setBrandList,
                             setMaterialList,
                             setStandardsList,
                             setSizeList,
                             setShapeList,

                             // Other props
                             handleCheckboxChange,
                             applyFilters,
                             applicationsList,
                             itemGroup,
                         }: any) => {
    const [visibility, setVisibility] = useState<{ [key: string]: boolean }>({
        Colour: false,
        Design: false,
        Types: false,
        Brand: false,
        Material: false,
        Standards: false,
        Size: false,
        Shape: false,
    });

    const isMobile = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        if (isMobile) {
            setVisibility({
                Colour: false,
                Design: false,
                Types: false,
                Brand: false,
                Material: false,
                Standards: false,
                Size: false,
            });
        }
    }, [isMobile]);

    // Define all filter categories
    const filterCategories: FilterCategory[] = [
        {
            title: 'Colour',
            available: availableColours,
            filtered: filteredColours,
            list: colourList,
            setList: setColourList,
            keyName: 'colours',
            paramKey: 'colours',
        },
        {
            title: 'Design',
            available: availableDesigns,
            filtered: filteredDesigns,
            list: designList,
            setList: setDesignList,
            keyName: 'design_style',
            paramKey: 'design_style',
        },
        {
            title: 'Types',
            available: availableTypes,
            filtered: filteredTypes,
            list: typesList,
            setList: setTypesList,
            keyName: 'types',
            paramKey: 'types',
        },
        {
            title: 'Brand',
            available: availableBrands,
            filtered: filteredBrands,
            list: brandList,
            setList: setBrandList,
            keyName: 'brand',
            paramKey: 'brand',
        },
        {
            title: 'Material',
            available: availableMaterials,
            filtered: filteredMaterials,
            list: materialList,
            setList: setMaterialList,
            keyName: 'material',
            paramKey: 'material',
        },
        {
            title: 'Standards',
            available: availableStandards,
            filtered: filteredStandards,
            list: standardsList,
            setList: setStandardsList,
            keyName: 'standards',
            paramKey: 'standards',
        },
        {
            title: 'Size',
            available: availableSizes,
            filtered: filteredSizes,
            list: sizeList,
            setList: setSizeList,
            keyName: '',
            paramKey: 'sizes',
        },
        {
            title: 'Shape',
            available: availableShapes,
            filtered: filteredShapes,
            list: shapeList,
            setList: setShapeList,
            keyName: '',
            paramKey: 'shapes',
        },
    ];

    const getFilteredOptions = (available: any[], filtered: any[], category: string) => {
        // If no application selected, return entire array
        if (!applicationsList || applicationsList.length === 0) {
            return available;
        }

        // If category is "Size" or "Shape", do string-based filtering
        if (category === 'Size' || category === 'Shape') {
            const lowerFiltered = filtered.map((sz: string) => sz.toLowerCase());
            return available.filter((item) => lowerFiltered.includes(item.toLowerCase()));
        }

        // For everything else, assume we have objects with "idx" and "filtered" has { id }
        return available.filter((item) => {
            const itemId = String(item.idx);
            return filtered.some((f: { id: number }) => String(f.id) === itemId);
        });
    };

    return (
        <>
            {filterCategories.map((category) => {
                const filteredOptions = getFilteredOptions(
                    category.available,
                    category.filtered,
                    category.title
                );

                // Check if we have options after filtering
                if (!filteredOptions || filteredOptions.length === 0) return null;

                // For shapes, only show if item_group is '004'
                if (category.title === 'Shape' && itemGroup !== '004') return null;

                return (
                    <Box key={category.title}>
                        <Divider sx={{ backgroundColor: '#000000', m: '1rem 0' }} />
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography fontWeight="bold" fontSize="20px" mb={2}>
                                {category.title}
                            </Typography>
                            <Box
                                onClick={() => setVisibility((prev) => ({ ...prev, [category.title]: !prev[category.title] }))}
                                sx={{ cursor: 'pointer' }}
                            >
                                {visibility[category.title] ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                            </Box>
                        </Box>
                        {visibility[category.title] && (
                            <Filter
                                filterType={filteredOptions}  // Changed to use filteredOptions
                                filteredOptions={category.filtered}
                                handleCheckboxChange={handleCheckboxChange}
                                list={category.list}
                                setList={category.setList}
                                keyName={category.keyName}
                                paramKey={category.paramKey}
                            />
                        )}
                    </Box>
                );
            })}

            {/* Apply Button */}
            <Divider sx={{ backgroundColor: '#000000', m: '1rem 0' }} />
            <Button
                variant="contained"
                onClick={applyFilters}
                sx={{ width: { xs: '100%', lg: '50%' } }}
                disableElevation
            >
                Apply
            </Button>
            <Divider sx={{ backgroundColor: '#000000', m: '1rem 0' }} />
        </>
    );
};

export default FilterSelection;
